import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DigitalInput } from "../../../models/digitalinput";
import { ScPoint } from "./grid-styles";

interface Props {
  point: DigitalInput;
}

const StationGridPoint = ({ point }: Props) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {`${point.Config.Name}: ${
            point.State.Value
              ? point.Config.State1Desc
              : point.Config.State0Desc
          }`}
        </Tooltip>
      }
    >
      <ScPoint active={point.State.Value} />
    </OverlayTrigger>
  );
};

export default StationGridPoint;
