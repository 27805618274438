import { DigitalInput } from "../../../models/digitalinput";

interface Props {
  digitalInput: DigitalInput;
}

const StationCountersRow = ({ digitalInput }: Props) => {
  return (
    <tr>
      <th scope="row">{digitalInput.Point}</th>
      <td>{digitalInput.Config.CounterDesc}</td>
      <td>{digitalInput.State.CountTotal}</td>
      <td>{digitalInput.State.CountToday}</td>
      <td>{digitalInput.State.CountYday}</td>
    </tr>
  );
};

export default StationCountersRow;
