import { Container, Table } from "react-bootstrap";
import { StationReportDaily } from "../../models/station-list";
import LxCard from "../layout/LxCard";
import ReportExport from "./ReportExport";

interface Props {
  type: "Count" | "OnTime" | "OffTime";
  data: StationReportDaily[];
  stationId: string;
}

const DailyReport = ({ type, data, stationId }: Props) => {
  const disabled = data.length <= 0;

  return (
    <LxCard
      title={`Daily ${type} Report`}
      footer={
        <ReportExport
          disabled={disabled}
          report={data}
          title={`Station ${stationId} Monthly ${data[0].Point} Report ${data[0].ReportDay}.xlsx`}
          type="daily"
        />
      }
    >
      <Container>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">Point</th>
              <th scope="col">Date</th>
              <th scope="col">Daily Total</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.ReportDay}>
                <td>{row.Point}</td>
                <td>{row.ReportDay}</td>
                <td>{row.DailyTotal.toFixed(1)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </LxCard>
  );
};

export default DailyReport;
