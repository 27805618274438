import jwtDecode, { JwtPayload } from "jwt-decode";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import agent from "../api/agent";
import { User } from "../models/user";

// Define the type used for AuthContext
interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: any;
  login: (email: string, password: string, from: string) => void;
  logout: () => void;
  refreshToken: () => void;
}

// Create the AuthContext
const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

// Define the props for the provider
// so that it wrap child React nodes
interface Props {
  children: ReactNode;
}

// Provider component to wrap the App to provide the context
export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname, error]);

  const refreshToken = useCallback(async () => {
    try {
      const response = await agent.Users.refresh();

      if (response.FirstName && response.LastName && response.JwtToken) {
        setUser(response);
        localStorage.setItem("token", response.JwtToken);
        const { exp } = jwtDecode<JwtPayload>(response.JwtToken);
        if (exp) {
          const expires = new Date(exp * 1000);
          const timeout = expires.getTime() - Date.now() - 60 * 1000;
          setTimeout(refreshToken, timeout);
        }
      }
    } catch (error) {
      setError(error);
      navigate("/login", { state: { referrer: location } });
    }
  }, []);

  useEffect(() => {
    const firstRefresh = async () => {
      await refreshToken();
      setLoadingInitial(false);
    };

    firstRefresh();
  }, [refreshToken]);

  const login = useCallback(
    (email: string, password: string, from: string) => {
      setLoading(true);

      agent.Users.login(email, password)
        .then((result) => {
          if (result.JwtToken) {
            localStorage.setItem("token", result.JwtToken);
            setUser(result);
            navigate(from);
          }
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    },
    [navigate]
  );

  const logout = useCallback(() => {
    if (user && user.JwtToken) {
      agent.Users.logout();
      localStorage.removeItem("token");
    }
    setUser(undefined);
  }, [user]);

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
      refreshToken,
    }),
    [user, loading, error, login, logout, refreshToken]
  );

  let content = children;

  if (loadingInitial) {
    content = (
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <AuthContext.Provider value={memoedValue}>{content}</AuthContext.Provider>
  );
};

// Custom hook wrapping the useContext hook
// provides Auth context without extra imports
const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
