import styled, { keyframes } from "styled-components";

export const ScContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 125px);
  justify-content: center;
  grid-gap: 5px;
  font-family: "Open Sans", sans-serif;
`;

export const ScCard = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  height: 70px;
  font-size: 0.9em;
  text-align: center;
  white-space: nowrap;
`;

interface TitleProps {
  border: string;
}

export const ScTitle = styled.div<TitleProps>`
  background: ${(props) => props.border};
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #fafafa;
  width: 100%;
  height: 30%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

interface BodyProps {
  blink: boolean;
  color: string;
}

const flashing = (blinkColor: string) => keyframes`
  0% {
    background-color: #4db6ac;
  }
  100% {
    background-color: ${blinkColor};
  }
`;

export const ScBody = styled.div<BodyProps>`
  width: 100%;
  height: 70%;
  background: ${(props) => props.color};
  font-size: 1.25em;
  display: grid;
  grid-template-columns: repeat(auto-fill, 20px);
  justify-content: center;
  grid-gap: 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  animation: ${(props) => flashing(props.color)}
    ${(props) => (props.blink ? "2000ms" : "")} infinite;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  position: relative;
`;

interface PointProps {
  active: boolean;
}

export const ScPoint = styled.span<PointProps>`
  height: 20px;
  width: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #212121;
  background-color: ${(props) => (props.active ? "#F44336" : "#4CAF50")};
  border-radius: 50%;
  display: inline-block;
`;
