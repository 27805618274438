import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import agent from "../api/agent";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import LxCard from "../components/layout/LxCard";
import StationOverviewTableList from "../components/stations/overview/StationOverviewTableList";
import { BreadCrumbPath } from "../models/breadcrumb-path";

// Station overview page
const Stations = () => {
  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Stations",
      link: "/stations",
      active: true,
    },
  ];

  useEffect(() => {
    document.title = "LxPro - Stations Overview";
  }, []);

  const { isLoading, data } = useQuery("overview", agent.Stations.overview, {
    refetchInterval: 5000,
  });

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      <h1>Stations</h1>
      <p>Overview for all stations</p>
      <LxCard title="Status">
        {isLoading && <Spinner animation="border" />}
        {!isLoading && data && <StationOverviewTableList data={data} />}
      </LxCard>
    </>
  );
};

export default Stations;
