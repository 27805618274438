import { useEffect, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import agent from "../api/agent";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import DailyReport from "../components/reports/DailyReport";
import DailyReportForm from "../components/reports/DailyReportForm";
import HourlyReport from "../components/reports/HourlyReport";
import HourlyReportForm from "../components/reports/HourlyReportForm";
import { BreadCrumbPath } from "../models/breadcrumb-path";
import {
  StationReportDaily,
  StationReportForm,
  StationReportHourly,
} from "../models/station-list";

const ReportsCount = () => {
  const { id } = useParams<{ id: string }>();

  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Reports",
      link: "/reports",
      active: false,
    },
    {
      name: "Count",
      link: `/reports/count/${id}`,
      active: true,
    },
  ];

  useEffect(() => {
    document.title = `LxPro - Station ${id} Count Report`;
  }, [id]);

  const [points, setPoints] = useState<StationReportForm[] | undefined>(
    undefined
  );
  const [dailyReport, setDailyReport] = useState<
    StationReportDaily[] | undefined
  >(undefined);
  const [hourlyReport, setHourlyReport] = useState<
    StationReportHourly[] | undefined
  >(undefined);

  useEffect(() => {
    const fetchPoints = async () => {
      const loadedPoints = await agent.Reports.countForm(id ?? "1");
      setPoints(loadedPoints);
    };

    fetchPoints();
  }, [id]);

  const onGenerateDailyReport = async (
    date: Date,
    point: StationReportForm
  ) => {
    const loadedDailyReport = await agent.Reports.countDaily(
      point.Id.toString(),
      date.toISOString()
    );
    setDailyReport(loadedDailyReport);
  };

  const onGenerateHourlyReport = async (
    start: Date,
    end: Date,
    point: StationReportForm
  ) => {
    const loadedHourlyReport = await agent.Reports.countHourly(
      point.Id.toString(),
      start.toISOString(),
      end.toISOString()
    );

    setHourlyReport(loadedHourlyReport);
  };

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      <h1>{`Station ${id} Counter Reports`}</h1>

      {!points && <Spinner animation="border" />}
      {points &&
        (points.length <= 0 ? (
          <h3>No Counter points enabled for this station.</h3>
        ) : (
          <Tabs defaultActiveKey="hourly">
            <Tab eventKey="hourly" title="Hourly">
              <HourlyReportForm
                points={points}
                onGenerateHourlyReport={onGenerateHourlyReport}
              />
              {hourlyReport &&
                (hourlyReport.length <= 0 ? (
                  <h3>No data found for this time range.</h3>
                ) : (
                  <HourlyReport
                    type="Count"
                    data={hourlyReport}
                    stationId={id ?? "1"}
                  />
                ))}
            </Tab>
            <Tab eventKey="daily" title="Daily">
              <DailyReportForm
                points={points}
                onGenerateDailyReport={onGenerateDailyReport}
              />
              {dailyReport &&
                (dailyReport.length <= 0 ? (
                  <h3>No data found for this time range.</h3>
                ) : (
                  <DailyReport
                    type="Count"
                    data={dailyReport}
                    stationId={id ?? "1"}
                  />
                ))}
            </Tab>
          </Tabs>
        ))}
    </>
  );
};
export default ReportsCount;
