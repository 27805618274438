import { Navigate } from "react-router";
import useAuth from "../../contexts/useAuth";

type Props = {
  children: JSX.Element;
};

const AdminRoute = ({ children }: Props) => {
  const { user } = useAuth();

  if (!user) return <Navigate replace to="/login" />;

  if (user.Role !== "Admin") return <Navigate replace to="/" />;

  return children;
};

export default AdminRoute;
