import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Spinner } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import agent from "../../api/agent";

interface Props {
  id: number;
}

const PollButton = ({ id }: Props) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => await agent.Stations.poll(id.toString()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("overview");
        queryClient.invalidateQueries("map");
        queryClient.invalidateQueries(["station", id.toString()]);
      },
    }
  );

  const pollClickHandler = async () => {
    await mutation.mutateAsync();
  };

  return (
    <Button
      variant="light"
      onClick={pollClickHandler}
      disabled={mutation.isLoading}
    >
      {mutation.isLoading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <FontAwesomeIcon icon="sync-alt" />
      )}
    </Button>
  );
};

export default PollButton;
