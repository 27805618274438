import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { StationCreateRequest } from "../../models/station";
import agent from "../../api/agent";
import { toast } from "react-toastify";

const schema = yup
  .object({
    StationId: yup.number().positive().integer().required(),
    Enable: yup.bool().required(),
    SiteName: yup.string().required(),
    Address: yup.string().required(),
    Region: yup.string().required(),
    IpAddress: yup.string().required(),
    Longitude: yup.number().required().min(-180.0).max(180.0),
    Latitude: yup.number().required().min(-90.0).max(90.0),
  })
  .required();

const StationCreateForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<StationCreateRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      Address: "",
      Enable: false,
      IpAddress: "",
      Latitude: 27.5,
      Longitude: -84,
      SiteName: "",
      StationId: 1,
    },
  });

  const onSubmit: SubmitHandler<StationCreateRequest> = async (data) => {
    try {
      await agent.Stations.create(data);
      toast.success(`Station ${data.StationId} created!`);
    } catch (error) {
      toast.error("Error creating station.");
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group className="mb-3" controlId="stationId" as={Col}>
            <Form.Label>Station Id</Form.Label>
            <Controller
              name="StationId"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="number"
                  {...field}
                  isInvalid={!!errors.StationId}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.StationId?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="siteName" as={Col}>
            <Form.Label>Site Name</Form.Label>
            <Controller
              name="SiteName"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  {...field}
                  isInvalid={!!errors.SiteName}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.SiteName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="enable" as={Col}>
            <Form.Label>Enable</Form.Label>
            <Controller
              name="Enable"
              control={control}
              render={({ field }) => (
                <Form.Check
                  type="switch"
                  ref={field.ref}
                  onBlur={field.onBlur}
                  name={field.name}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  isInvalid={!!errors.Enable}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.Enable?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className="mb-3" controlId="address">
          <Form.Label>Address</Form.Label>
          <Controller
            name="Address"
            control={control}
            render={({ field }) => (
              <Form.Control
                type="text"
                {...field}
                isInvalid={!!errors.Address}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.Address?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Row>
          <Form.Group className="mb-3" controlId="region" as={Col}>
            <Form.Label>Region</Form.Label>
            <Controller
              name="Region"
              control={control}
              render={({ field }) => (
                <Form.Select
                  aria-label="Select Region"
                  {...field}
                  isInvalid={!!errors.Region}
                >
                  <option>Select Region</option>
                  <option value="North">North</option>
                  <option value="Central">Central</option>
                  <option value="South">South</option>
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.Region?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="ipAddress" as={Col}>
            <Form.Label>IP Address</Form.Label>
            <Controller
              name="IpAddress"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  {...field}
                  isInvalid={!!errors.IpAddress}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.IpAddress?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3" controlId="longitude" as={Col}>
            <Form.Label>Longitude</Form.Label>
            <Controller
              name="Longitude"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="number"
                  {...field}
                  isInvalid={!!errors.Longitude}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.Longitude?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="latitude" as={Col}>
            <Form.Label>Latitude</Form.Label>
            <Controller
              name="Latitude"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="number"
                  {...field}
                  isInvalid={!!errors.Latitude}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.Latitude?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  );
};

export default StationCreateForm;
