import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { User, UserResponse, UserUpdateRequest } from "../../models/user";

interface Props {
  user: User | UserResponse;
  onUpdate: (id: string, user: UserUpdateRequest) => void;
}

const UserUpdateForm = ({ user, onUpdate }: Props) => {
  const [firstName, setFirstName] = useState<string>(user.FirstName);
  const [lastName, setLastName] = useState<string>(user.LastName);
  const [email, setEmail] = useState<string>(user.Email);
  const [phoneNumber, setPhoneNumber] = useState<string>(user.PhoneNumber);
  const [role, setRole] = useState<string>(user.Role);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const firstNameHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFirstName(event.target.value);
  const lastNameHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLastName(event.target.value);
  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  const phoneNumberHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPhoneNumber(event.target.value);
  const roleHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setRole(event.target.value);
  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const confirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(event.target.value);

  const updateHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const userRequest: UserUpdateRequest = {};

    if (firstName !== user.FirstName) {
      userRequest.FirstName = firstName;
    }

    if (lastName !== user.LastName) {
      userRequest.LastName = lastName;
    }

    if (email !== user.Email) {
      userRequest.Email = email;
    }

    if (phoneNumber !== user.PhoneNumber) {
      userRequest.PhoneNumber = phoneNumber;
    }

    if (role !== user.Role) {
      userRequest.Role = role;
    }

    if (password !== "" && password === confirmPassword) {
      userRequest.Password = password;
      userRequest.ConfirmPassword = confirmPassword;
    }

    onUpdate(user.Id.toString(), userRequest);
  };

  return (
    <Form onSubmit={updateHandler}>
      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          required
          type="text"
          value={firstName}
          onChange={firstNameHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          required
          type="text"
          value={lastName}
          onChange={lastNameHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          type="email"
          value={email}
          onChange={emailHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          required
          type="text"
          value={phoneNumber}
          onChange={phoneNumberHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Role</Form.Label>
        <Form.Select required value={role} onChange={roleHandler}>
          <option value="User">User</option>
          <option value="Admin">Admin</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          placeholder="Leave blank to keep current password."
          onChange={passwordHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          value={confirmPassword}
          placeholder="Leave blank to keep current password."
          onChange={confirmPasswordHandler}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Save Changes
      </Button>
    </Form>
  );
};

export default UserUpdateForm;
