import {
  createTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  ColumnFiltersState,
  SortingState,
  useTableInstance,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StationConfigResponse } from "../../models/station";
import ManageStationFilter from "./ManageStationFilter";
import { useNavigate } from "react-router-dom";

interface Props {
  data: StationConfigResponse[];
}

let table = createTable().setRowType<StationConfigResponse>();

const MangeStationsTable = ({ data }: Props) => {
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo(
    () => [
      table.createDataColumn("StationId", {
        cell: (info) => info.getValue(),
        header: () => <span>Station ID</span>,
      }),
      table.createDataColumn("SiteName", {
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
      }),
      table.createDataColumn("Address", {
        cell: (info) => info.getValue(),
        header: () => <span>Address</span>,
      }),
      table.createDataColumn("Region", {
        cell: (info) => info.getValue(),
        header: () => <span>Region</span>,
      }),
      table.createDataColumn("IpAddress", {
        cell: (info) => info.getValue(),
        header: () => <span>IP Address</span>,
      }),
      table.createDataColumn("Longitude", {
        cell: (info) => info.getValue(),
        header: () => <span>Longitude</span>,
        enableColumnFilter: false,
      }),
      table.createDataColumn("Latitude", {
        cell: (info) => info.getValue(),
        header: () => <span>Latitude</span>,
        enableColumnFilter: false,
      }),
    ],
    []
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const instance = useTableInstance(table, {
    data,
    columns,
    state: {
      columnFilters,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Table responsive striped hover>
      <thead>
        {instance.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {header.renderHeader()}
                        {{
                          asc: (
                            <>
                              {" "}
                              <FontAwesomeIcon icon="angle-up" />
                            </>
                          ),
                          desc: (
                            <>
                              {" "}
                              <FontAwesomeIcon icon="angle-down" />
                            </>
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                      {header.column.getCanFilter() ? (
                        <div>
                          <ManageStationFilter
                            column={header.column}
                            instance={instance}
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </th>
              );
            })}
            <th>Edit</th>
          </tr>
        ))}
      </thead>
      <tbody>
        {instance.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>{cell.renderCell()}</td>
            ))}
            <td>
              <Button
                variant="light"
                onClick={() =>
                  navigate(
                    `/config/stations/${row.getVisibleCells()[0].getValue()}`
                  )
                }
              >
                <FontAwesomeIcon icon="edit" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MangeStationsTable;
