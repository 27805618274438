import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserResponse } from "../../models/user";
import { Button } from "react-bootstrap";

interface Props {
  user: UserResponse;
  onEdit: (user: UserResponse) => void;
  onDelete: (id: string) => void;
}

const UsersRow = ({ user, onEdit, onDelete }: Props) => {
  return (
    <tr>
      <th scope="row">{user.Id}</th>
      <td>{user.FirstName}</td>
      <td>{user.LastName}</td>
      <td>{user.Email}</td>
      <td>{user.PhoneNumber}</td>
      <td>{user.Role}</td>
      <td>
        <Button variant="light" onClick={() => onEdit(user)}>
          <FontAwesomeIcon icon="edit" />
        </Button>
      </td>
      <td>
        <Button
          variant="light"
          onDoubleClick={() => onDelete(user.Id.toString())}
        >
          <FontAwesomeIcon icon="trash-alt" />
        </Button>
      </td>
    </tr>
  );
};

export default UsersRow;
