import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import agent from "../api/agent";
import AlarmLogDetail from "../components/alarms/AlarmLogDetail";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import { BreadCrumbPath } from "../models/breadcrumb-path";

const AlarmLog = () => {
  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Alarms",
      link: "/alarms",
      active: true,
    },
  ];

  useEffect(() => {
    document.title = "LxPro - Alarm Log";
  }, []);

  const { isLoading, data } = useQuery("overview", agent.Stations.list);

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      <h1>Alarm Log</h1>
      {!isLoading && data && <AlarmLogDetail stations={data} />}
      {isLoading && <Spinner animation="border" />}
    </>
  );
};

export default AlarmLog;
