import { Navigate, useLocation } from "react-router";
import useAuth from "../../contexts/useAuth";

type Props = {
  children: JSX.Element;
};

const AuthenticatedRoute = ({ children }: Props) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user)
    return <Navigate replace to="/login" state={{ referrer: location }} />;

  return children;
};

export default AuthenticatedRoute;
