import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import NiceModal from "@ebay/nice-modal-react";
import agent from "../../api/agent";
import LxCard from "../layout/LxCard";
import ManageStationsTable from "./ManageStationsTable";
import StationCreateForm from "../forms/StationCreateForm";

const ManageStations = () => {
  const { isLoading, data } = useQuery("stationsconfig", agent.Stations.all);

  return (
    <LxCard title="Manage Stations">
      <Row>
        <Col md={{ span: 10, offset: 10 }}>
          <Button
            onClick={() =>
              NiceModal.show("edit-alert", {
                title: "Create New Station",
                size: "lg",
                children: <StationCreateForm />,
              })
            }
          >
            <FontAwesomeIcon icon="plus" /> New Station
          </Button>
        </Col>
      </Row>
      <Row>
        {isLoading && <Spinner animation="border" />}
        {!isLoading && data && data.length > 0 && (
          <ManageStationsTable data={data} />
        )}
      </Row>
    </LxCard>
  );
};

export default ManageStations;
