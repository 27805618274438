import { useEffect, useState } from "react";
import SignalRContext from "../contexts/SignalRContext";
import { Station } from "../models/station";
import { StationMapResponse } from "../models/station-map";
import { StationOverview } from "../models/station-overview";

export const useStation = (stationId: string) => {
    const [data, setData] = useState<Station | undefined>(undefined);

    SignalRContext.useSignalREffect(
        `station-${stationId}`,
        (message) => {
            setData(message);
        },
        [stationId]
    );

    useEffect(() => {
        const addGroup = async () => {
            await SignalRContext.invoke("AddToGroup", `station-${stationId}`);
        };

        // const removeGroup = async () => {
        //     await SignalRContext.invoke(
        //         "RemoveFromGroup",
        //         `station-${stationId}`
        //     );
        // };

        const interval = setInterval(() => {
            if (!data) {
                addGroup();
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
            // removeGroup();
        };
    }, [data, stationId]);

    return data;
};

export const useOverview = () => {
    const [data, setData] = useState<StationOverview[]>([]);

    SignalRContext.useSignalREffect(
        "overview",
        (message) => {
            setData(message);
        },
        ["overview"]
    );

    useEffect(() => {
        const addGroup = async () => {
            await SignalRContext.invoke("AddToGroup", "overview");
        };

        // const removeGroup = async () => {
        //     await SignalRContext.invoke(
        //         "RemoveFromGroup",
        //         `station-${station.StationId}`
        //     );
        // };

        const interval = setInterval(() => {
            if (data.length === 0) {
                addGroup();
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
            // removeGroup();
        };
    }, [data]);

    return data;
};

export const useMapData = () => {
    const [data, setData] = useState<StationMapResponse[]>([]);

    SignalRContext.useSignalREffect(
        "map",
        (message) => {
            setData(message);
        },
        []
    );

    useEffect(() => {
        const addGroup = async () => {
            await SignalRContext.invoke("AddToGroup", "map");
        };

        // const removeGroup = async () => {
        //     await SignalRContext.invoke(
        //         "RemoveFromGroup",
        //         `station-${station.StationId}`
        //     );
        // };

        const interval = setInterval(() => {
            if (data.length === 0) {
                addGroup();
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
            // removeGroup();
        };
    }, [data]);

    return data;
};