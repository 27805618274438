import { useState } from "react";
import agent from "../../api/agent";
import { AlarmLogResponse } from "../../models/alarm";
import { StationOverview } from "../../models/station-overview";
import AlarmLogForm from "./AlarmLogForm";
import AlarmLogTable from "./AlarmLogTable";

interface Props {
  stations: StationOverview[];
}

const AlarmLogDetail = ({ stations }: Props) => {
  const [alarms, setAlarms] = useState<AlarmLogResponse[]>([]);

  const onGenerateAlarmLog = async (
    start: Date,
    end: Date,
    stations: string
  ) => {
    const alarmLog = await agent.Stations.alarms(
      start.toISOString(),
      end.toISOString(),
      stations
    );

    setAlarms(alarmLog);
  };

  return (
    <>
      <AlarmLogForm
        stations={stations}
        onGenerateAlarmLog={onGenerateAlarmLog}
      />
      {alarms.length > 0 && <AlarmLogTable alarms={alarms} />}
    </>
  );
};

export default AlarmLogDetail;
