import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  AccountStationResponse,
  AccountStationUpdateRequest,
} from "../../models/user";

interface Props {
  alert: AccountStationResponse;
  updateAlertSetting: (alert: AccountStationUpdateRequest) => void;
}

const UserAlertUpdateForm = ({ alert, updateAlertSetting }: Props) => {
  const [text, setText] = useState<boolean>(alert.SendText);
  const [email, setEmail] = useState<boolean>(alert.SendEmail);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateAlertSetting({
      Id: alert.Id,
      SendText: text,
      SendEmail: email,
    });
  };

  const textCheckHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setText((text) => !text);
  const emailCheckHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail((email) => !email);

  return (
    <Form onSubmit={submitHandler}>
      <Form.Group>
        <Form.Label>Send Email?</Form.Label>
        <Form.Check
          type="switch"
          checked={email}
          onChange={emailCheckHandler}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Send Text?</Form.Label>
        <Form.Check type="switch" checked={text} onChange={textCheckHandler} />
      </Form.Group>

      <Button variant="primary" type="submit">
        Update
      </Button>
    </Form>
  );
};

export default UserAlertUpdateForm;
