import { ReactNode } from "react";
import { Card } from "react-bootstrap";
import { Color } from "react-bootstrap/esm/types";

interface Props {
  title: string;
  children: ReactNode;
  footer?: ReactNode;
  type?: string;
  text?: Color;
}

const LxCard = ({ title, children, footer, type, text }: Props) => {
  return (
    <Card className="mb-3" bg={type} text={text}>
      <Card.Header as="h5">{title}</Card.Header>
      <Card.Body>{children}</Card.Body>
      {footer && <Card.Footer>{footer}</Card.Footer>}
    </Card>
  );
};

export default LxCard;
