import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import StationGrid from "../components/stations/grid/StationGridSignalR";
import { useOverview } from "../hooks/use-signalr";

const Grid = () => {
    useEffect(() => {
        document.title = "LxPro - Stations Grid View";
    }, []);

    const stations = useOverview();

    return (
        <>
            {stations.length === 0 && <Spinner animation="border" />}
            {stations.length > 0 && <StationGrid stations={stations} />}
        </>
    );

    // const { isLoading, data } = useQuery("stations", agent.Stations.list, {
    //   refetchInterval: 5000,
    // });

    // return (
    //   <>
    //     {isLoading && <Spinner animation="border" />}
    //     {!isLoading && data && <StationGrid stations={data} />}
    //   </>
    // );
};

export default Grid;
