import { Table } from "react-bootstrap";
import { DigitalInput } from "../../../models/digitalinput";
import StationCountersRow from "./StationCountersRow";

interface Props {
  digitalInputs: DigitalInput[];
}

const StationCountersTable = ({ digitalInputs }: Props) => {
  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th scope="col">Point</th>
          <th scope="col">Name</th>
          <th scope="col">Count Total</th>
          <th scope="col">Count Today</th>
          <th scope="col">Count Yesterday</th>
        </tr>
      </thead>
      <tbody>
        {digitalInputs.map((digitalInput) => (
          <StationCountersRow
            key={digitalInput.Point}
            digitalInput={digitalInput}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default StationCountersTable;
