import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import agent from "../../api/agent";
import useAuth from "../../contexts/useAuth";
import { StationSelect } from "../../models/station";
import { AccountStationCreateRequest } from "../../models/user";

interface Props {
  addAlertSetting: (alert: AccountStationCreateRequest) => void;
}

const UserAlertCreateForm = ({ addAlertSetting }: Props) => {
  const [stations, setStations] = useState<StationSelect[] | undefined>(
    undefined
  );

  const { user } = useAuth();

  const [stationId, setStationId] = useState<string>("");
  const [text, setText] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);

  useEffect(() => {
    const fetchStations = async () => {
      const loadedStations = await agent.Stations.names();
      setStationId(loadedStations[0].Id.toString());
      setStations(loadedStations);
    };

    fetchStations();
  }, []);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (user && user.Id) {
      addAlertSetting({
        AccountId: user?.Id,
        StationId: parseInt(stationId),
        SendText: text,
        SendEmail: email,
      });
    }
  };

  const stationSelectHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStationId(event.target.value);
  };

  const textCheckHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setText((text) => !text);
  const emailCheckHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail((email) => !email);

  return (
    <>
      {stations && (
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3">
            <Form.Label>Station</Form.Label>
            <Form.Select value={stationId} onChange={stationSelectHandler}>
              {stations.map((station) => (
                <option key={station.Id} value={station.Id}>
                  {`${station.Id} - ${station.SiteName}`}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Send Email?</Form.Label>
            <Form.Check
              type="switch"
              checked={email}
              onChange={emailCheckHandler}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Send Text?</Form.Label>
            <Form.Check
              type="switch"
              checked={text}
              onChange={textCheckHandler}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Add
          </Button>
        </Form>
      )}
      {!stations && <Spinner animation="border" />}
    </>
  );
};

export default UserAlertCreateForm;
