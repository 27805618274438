import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStation } from "../../../hooks/use-signalr";
import { StationMapResponse } from "../../../models/station-map";
import { StationOverview } from "../../../models/station-overview";
import { ScBody, ScCard, ScTitle } from "./grid-styles";
import StationGridPoint from "./StationGridPoint";

interface Props {
  station: StationOverview | StationMapResponse;
}

const StationCard = ({ station }: Props) => {
  const navigate = useNavigate();

  const data = useStation(station.StationId.toString());

  return (
    <ScCard onClick={() => navigate(`/stations/${station.StationId}`)}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {station.AlarmState && "ALARM"}
            {station.CommFailureState && "COMM FAIL"}
            {!station.AlarmState && !station.CommFailureState && "NORMAL"}
          </Tooltip>
        }
      >
        <ScTitle border="#9E9E9E">{`${station.StationId} - ${station.SiteName}`}</ScTitle>
      </OverlayTrigger>
      <ScBody
        blink={station.AlarmState || station.CommFailureState}
        color={
          station.AlarmState
            ? "#FFEB3B"
            : station.CommFailureState
            ? "#F44336"
            : "#4db6ac"
        }
      >
        {!data && <Spinner animation="border" />}
        {data &&
          data.DigitalInputs.filter((point) => point.Config.Enable).map(
            (point) => <StationGridPoint key={point.Point} point={point} />
          )}
      </ScBody>
    </ScCard>
  );
};

export default StationCard;
