import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import agent from "../../api/agent";
import { BreadCrumbPath } from "../../models/breadcrumb-path";
import StationEditForm from "../forms/StationEditForm";
import AutoBreadCrumb from "../layout/AutoBreadCrumb";
import LxCard from "../layout/LxCard";

// Individual station page
const ManageStation = () => {
  // Holds the id from the url parameter
  const { id } = useParams<{ id: string }>();

  // Holds the currently loaded station
  const { isLoading, data } = useQuery(["station-config", id], () =>
    agent.Stations.config(id ?? "1")
  );

  // Loads the station if the id changes
  // also sets an interval to continuously refresh
  // the station data
  useEffect(() => {
    document.title = `LxPro - Station ${id} Detail`;
  }, [id]);

  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Config",
      link: "/config",
      active: true,
    },
    {
      name: "Stations",
      link: "/config/stations",
      active: false,
    },
    {
      name: id ?? "1",
      link: `/config/stations/${id}`,
      active: true,
    },
  ];

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      <h1>{`Station ${id}`}</h1>
      <LxCard title="Edit Station">
        {!isLoading && data && <StationEditForm station={data} />}
        {isLoading && <Spinner animation="border" />}
      </LxCard>
    </>
  );
};

export default ManageStation;
