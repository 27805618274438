import { Table } from "react-bootstrap";
import { DigitalInput } from "../../../models/digitalinput";
import StationOffTimesRow from "./StationOffTimesRow";

interface Props {
  digitalInputs: DigitalInput[];
}

const StationOffTimesTable = ({ digitalInputs }: Props) => {
  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th scope="col">Point</th>
          <th scope="col">Name</th>
          <th scope="col">Off Time Total</th>
          <th scope="col">Off Time Today</th>
          <th scope="col">Off Time Yesterday</th>
        </tr>
      </thead>
      <tbody>
        {digitalInputs.map((digitalInput) => (
          <StationOffTimesRow
            key={digitalInput.Point}
            digitalInput={digitalInput}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default StationOffTimesTable;
