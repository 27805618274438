import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import agent from "../api/agent";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import LxCard from "../components/layout/LxCard";
import ReportListTable from "../components/reports/ReportListTable";
import { BreadCrumbPath } from "../models/breadcrumb-path";

const Reports = () => {
  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Reports",
      link: "/reports",
      active: true,
    },
  ];

  useEffect(() => {
    document.title = "LxPro - Reports";
  }, []);

  const { isLoading, data } = useQuery("overview", agent.Stations.list);

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      <h1>Reports</h1>
      <LxCard title="Station Monthly Reports">
        {!isLoading && data && <ReportListTable stations={data} />}
        {isLoading && <Spinner animation="border" />}
      </LxCard>
    </>
  );
};
export default Reports;
