import { Link } from "react-router-dom";
import { StationOverview } from "../../models/station-overview";

interface Props {
  station: StationOverview;
}

const ReportListRow = ({ station }: Props) => {
  return (
    <tr>
      <th scope="row">
        <Link to={`/stations/${station.StationId}`}>{station.StationId}</Link>
      </th>
      <td>{station.SiteName}</td>
      <td>
        <Link to={`/reports/count/${station.StationId}`}>Counts</Link>
      </td>
      <td>
        <Link to={`/reports/ontime/${station.StationId}`}>On Time</Link>
      </td>
      <td>
        <Link to={`/reports/offtime/${station.StationId}`}>Off Time</Link>
      </td>
    </tr>
  );
};

export default ReportListRow;
