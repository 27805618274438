import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Props {
  stationId: string;
}

const StationButton = ({ stationId }: Props) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="light"
      onClick={() => navigate(`/stations/${stationId}`)}
    >
      {stationId}
    </Button>
  );
};

export default StationButton;
