import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { UserCreateRequest } from "../../models/user";

interface Props {
  onCreate: (user: UserCreateRequest) => void;
}

const UserCreateForm = ({ onCreate }: Props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [role, setRole] = useState<string>("User");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const firstNameHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFirstName(event.target.value);
  const lastNameHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLastName(event.target.value);
  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  const phoneNumberHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPhoneNumber(event.target.value);
  const roleHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setRole(event.target.value);
  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const confirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(event.target.value);

  const createHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const userRequest: UserCreateRequest = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      PhoneNumber: phoneNumber,
      Role: role,
      Password: password,
      ConfirmPassword: confirmPassword,
    };

    onCreate(userRequest);
  };

  return (
    <Form onSubmit={createHandler}>
      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          required
          type="text"
          value={firstName}
          onChange={firstNameHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          required
          type="text"
          value={lastName}
          onChange={lastNameHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          type="email"
          value={email}
          onChange={emailHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          required
          type="text"
          value={phoneNumber}
          onChange={phoneNumberHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Role</Form.Label>
        <Form.Select required value={role} onChange={roleHandler}>
          <option value="User">User</option>
          <option value="Admin">Admin</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          value={password}
          onChange={passwordHandler}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          required
          type="password"
          value={confirmPassword}
          onChange={confirmPasswordHandler}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Save Changes
      </Button>
    </Form>
  );
};

export default UserCreateForm;
