import { Alert, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NotFound = () => {
  return (
    <Alert variant="danger">
      <Alert.Heading>404 - Not Found</Alert.Heading>
      <p>The page requested was not found.</p>
      <hr />
      <LinkContainer to="/">
        <Button>Return to home page</Button>
      </LinkContainer>
    </Alert>
  );
};

export default NotFound;
