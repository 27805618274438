import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { ReactNode } from "react";

interface Props {
  title: string;
  size: "lg" | "sm" | "xl" | undefined;
  children: ReactNode;
}

const LxModal = NiceModal.create(
  ({ title, size = undefined, children }: Props) => {
    const modal = useModal();
    return (
      <BootstrapModal {...bootstrapDialog(modal)} title={title} size={size}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>{children}</BootstrapModal.Body>

        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={modal.hide}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  }
);

export default LxModal;
