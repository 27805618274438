import { Table } from "react-bootstrap";
import { StationOverview } from "../../models/station-overview";
import ReportListRow from "./ReportListRow";

interface Props {
  stations: StationOverview[];
}

const ReportListTable = ({ stations }: Props) => {
  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th scope="col">Station ID</th>
          <th scope="col">Name</th>
          <th scope="col">Count Report</th>
          <th scope="col">On Time Report</th>
          <th scope="col">Off Time Report</th>
        </tr>
      </thead>
      <tbody>
        {stations.map((station) => (
          <ReportListRow key={station.StationId} station={station} />
        ))}
      </tbody>
    </Table>
  );
};

export default ReportListTable;
