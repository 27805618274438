import { DigitalInput } from "../../../models/digitalinput";

interface Props {
  digitalInput: DigitalInput;
}

const StationStatusRow = ({ digitalInput }: Props) => {
  const pointState = digitalInput.State.Value
    ? digitalInput.Config.State1Desc
    : digitalInput.Config.State0Desc;

  return (
    <tr className={digitalInput.State.AlarmState ? "table-warning" : ""}>
      <th scope="row">{digitalInput.Point}</th>
      <td>{digitalInput.Config.Name}</td>
      <td>{pointState}</td>
    </tr>
  );
};

export default StationStatusRow;
