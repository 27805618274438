import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import React from "react";
import { Button } from "react-bootstrap";
import { AlarmLogResponse } from "../../models/alarm";

interface Props {
  disabled: boolean;
  report: AlarmLogResponse[];
  title: string;
}

const AlarmExport = ({ disabled, report, title }: Props) => {
  const exportRef = React.createRef<ExcelExport>();

  const exportExcel = () => {
    if (exportRef.current) {
      exportRef.current.save();
    }
  };

  return (
    <>
      <Button onClick={exportExcel} disabled={disabled}>
        Save
      </Button>
      <ExcelExport data={report} fileName={title} ref={exportRef}>
        <ExcelExportColumn field="OnTime" title="On Time" />
        <ExcelExportColumn field="OffTime" title="Off Time" />
        <ExcelExportColumn field="Duration" title="Duration (sec)" />
        <ExcelExportColumn field="StationId" title="Station Id" />
        <ExcelExportColumn field="SiteName" title="Site Name" />
        <ExcelExportColumn field="Point" title="Point" />
        <ExcelExportColumn field="Name" title="Name" />
        <ExcelExportColumn field="State" title="State" />
      </ExcelExport>
    </>
  );
};

export default AlarmExport;
