import { Table } from "react-bootstrap";
import { DigitalInput } from "../../../models/digitalinput";
import StationOnTimesRow from "./StationOnTimesRow";

interface Props {
  digitalInputs: DigitalInput[];
}

const StationOnTimesTable = ({ digitalInputs }: Props) => {
  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th scope="col">Point</th>
          <th scope="col">Name</th>
          <th scope="col">On Time Total</th>
          <th scope="col">On Time Today</th>
          <th scope="col">On Time Yesterday</th>
        </tr>
      </thead>
      <tbody>
        {digitalInputs.map((digitalInput) => (
          <StationOnTimesRow
            key={digitalInput.Point}
            digitalInput={digitalInput}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default StationOnTimesTable;
