import { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import agent from "../../api/agent";
import { UserResponse, UserUpdateRequest } from "../../models/user";
import LxCard from "../layout/LxCard";
import LxModal from "../layout/LxModal";
import UserUpdateForm from "./UserUpdateForm";

interface Props {
  id: string;
}

NiceModal.register("user-details", LxModal);

const UserDetails = ({ id }: Props) => {
  const [user, setUser] = useState<UserResponse | undefined>(undefined);

  useEffect(() => {
    const fetchUser = async () => {
      const loadedUser = await agent.Users.detail(id);
      setUser(loadedUser);
    };

    fetchUser();
  }, [id]);

  const handleUpdateUser = async (id: string, user: UserUpdateRequest) => {
    try {
      const updatedUser = await agent.Users.update(id, user);
      setUser(updatedUser);
      toast.success("Successfully updated user details");
    } catch (error) {
      toast.error("Error updating user");
    }

    NiceModal.hide("user-details");
  };

  const showForm = () => {
    if (user) {
      NiceModal.show("user-details", {
        title: "Edit User Details",
        children: <UserUpdateForm user={user} onUpdate={handleUpdateUser} />,
      });
    }
  };

  return (
    <>
      {user && (
        <>
          <LxCard title="User Details">
            <Table responsive striped hover>
              <tbody>
                <tr>
                  <th scope="row">First Name</th>
                  <td>{user.FirstName}</td>
                </tr>
                <tr>
                  <th scope="row">Last Name</th>
                  <td>{user.LastName}</td>
                </tr>
                <tr>
                  <th scope="row">E-mail</th>
                  <td>{user.Email}</td>
                </tr>
                <tr>
                  <th scope="row">Phone Number</th>
                  <td>{user.PhoneNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Role</th>
                  <td>{user.Role}</td>
                </tr>
              </tbody>
            </Table>
            <Button onClick={() => showForm()}>Edit Details</Button>
          </LxCard>
        </>
      )}
      {!user && <Spinner animation="border" />}
    </>
  );
};

export default UserDetails;
