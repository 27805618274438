import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import agent from "../api/agent";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import DailyReport from "../components/reports/DailyReport";
import DailyReportForm from "../components/reports/DailyReportForm";
import { BreadCrumbPath } from "../models/breadcrumb-path";
import { StationReportDaily, StationReportForm } from "../models/station-list";

const ReportsOnTime = () => {
  const { id } = useParams<{ id: string }>();

  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Reports",
      link: "/reports",
      active: false,
    },
    {
      name: "On Time",
      link: `/reports/ontime/${id}`,
      active: true,
    },
  ];

  useEffect(() => {
    document.title = `LxPro - Station ${id} OffTime Report`;
  }, [id]);

  const [points, setPoints] = useState<StationReportForm[] | undefined>(
    undefined
  );
  const [dailyReport, setDailyReport] = useState<
    StationReportDaily[] | undefined
  >(undefined);

  useEffect(() => {
    const fetchPoints = async () => {
      const loadedPoints = await agent.Reports.ontimeForm(id ?? "1");
      setPoints(loadedPoints);
    };

    fetchPoints();
  }, [id]);

  const onGenerateDailyReport = async (
    date: Date,
    point: StationReportForm
  ) => {
    const loadedDailyReport = await agent.Reports.ontimeDaily(
      point.Id.toString(),
      date.toISOString()
    );

    loadedDailyReport.forEach((element) => (element.DailyTotal /= 10.0));

    setDailyReport(loadedDailyReport);
  };

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      <h1>{`Station ${id} On Time Reports`}</h1>
      {!points && <Spinner animation="border" />}
      {points &&
        (points.length <= 0 ? (
          <h3>No On Time points enabled for this station.</h3>
        ) : (
          <DailyReportForm
            points={points}
            onGenerateDailyReport={onGenerateDailyReport}
          />
        ))}
      {dailyReport &&
        (dailyReport.length <= 0 ? (
          <h3>No data found for this time range.</h3>
        ) : (
          <DailyReport type="OnTime" data={dailyReport} stationId={id ?? "1"} />
        ))}
    </>
  );
};
export default ReportsOnTime;
