import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import agent from "../api/agent";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import StationDetailContent from "../components/stations/details/StationDetailContent";
import { BreadCrumbPath } from "../models/breadcrumb-path";

// Individual station page
const Station = () => {
  // Holds the id from the url parameter
  const { id } = useParams<{ id: string }>();

  // Holds the currently loaded station
  const { isLoading, data } = useQuery(
    ["station", id],
    () => agent.Stations.detail(id ?? "1"),
    { refetchInterval: 5000 }
  );

  // Loads the station if the id changes
  // also sets an interval to continuously refresh
  // the station data
  useEffect(() => {
    document.title = `LxPro - Station ${id} Detail`;
  }, [id]);

  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Stations",
      link: "/stations",
      active: false,
    },
    {
      name: id ?? "1",
      link: `/stations/${id}`,
      active: true,
    },
  ];

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      <h1>{`Station ${id}`}</h1>
      {!isLoading && data && <StationDetailContent station={data} />}
      {isLoading && <Spinner animation="border" />}
    </>
  );
};

export default Station;
