import { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";

// Home page, pretty pointless just a landing page
const Home = () => {
  useEffect(() => {
    document.title = "LxPro - Home";
  }, []);

  return (
    <>
      <AutoBreadCrumb paths={[{ name: "Home", link: "/", active: true }]} />
      <Card>
        <Card.Body>
          <h1>LxPro</h1>
          <p className="lead">Remote Station Monitoring</p>
          <hr className="my-4" />
          <p>Monitor status of remote stations</p>
          <p className="lead">
            <LinkContainer to="/stations">
              <Button>View Stations</Button>
            </LinkContainer>
          </p>
        </Card.Body>
      </Card>
    </>
  );
};

export default Home;
