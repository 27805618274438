import AppSettings from "../../helpers/app-settings";

const MainFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <span className="text-muted">{`© ${new Date().getFullYear()} - ${
          AppSettings.CompanyName
        }`}</span>
      </div>
    </footer>
  );
};

export default MainFooter;
