import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { BreadCrumbPath } from "../../models/breadcrumb-path";

interface Props {
  paths: BreadCrumbPath[];
}

const AutoBreadCrumb = ({ paths }: Props) => {
  return (
    <Breadcrumb>
      {paths.map((path) => {
        if (path.active) {
          return (
            <Breadcrumb.Item key={path.name} active={path.active}>
              {path.name}
            </Breadcrumb.Item>
          );
        } else {
          return (
            <LinkContainer key={path.name} to={path.link}>
              <Breadcrumb.Item>{path.name}</Breadcrumb.Item>
            </LinkContainer>
          );
        }
      })}
    </Breadcrumb>
  );
};

export default AutoBreadCrumb;
