import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { StationReportForm } from "../../models/station-list";
import LxCard from "../layout/LxCard";

interface Props {
  points: StationReportForm[];
  onGenerateDailyReport: (date: Date, point: StationReportForm) => void;
}

const DailyReportForm = ({ points, onGenerateDailyReport }: Props) => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [point, setPoint] = useState<StationReportForm>(points[0]);

  const disabled = !!date && !!point;

  const dateChangeHandler = (event: DatePickerChangeEvent) => {
    setDate(event.value);
  };

  const pointChangeHandler = (event: DropDownListChangeEvent) => {
    setPoint(event.value);
  };

  const generateReportHandler = () => {
    if (date) {
      onGenerateDailyReport(date, point);
    }
  };

  return (
    <LxCard
      title="Select Date and Point"
      footer={
        <Button onClick={generateReportHandler} disabled={!disabled}>
          Generate
        </Button>
      }
    >
      <Row className="mb-3">
        <Col lg={4}>
          <label htmlFor="datepicker" className="form-label mt-4">
            Date
          </label>
          <DatePicker
            onChange={dateChangeHandler}
            value={date}
            id="datepicker"
            width="100%"
          />
        </Col>
        <Col lg={4}>
          <label htmlFor="pointSelect" className="form-label mt-4">
            Point
          </label>
          <DropDownList
            data={points}
            onChange={pointChangeHandler}
            value={point}
            id="pointSelect"
            style={{ width: "100%" }}
            textField="Name"
            dataItemKey="Id"
          />
        </Col>
      </Row>
    </LxCard>
  );
};
export default DailyReportForm;
