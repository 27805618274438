import { useMemo } from "react";
import { CircleMarker, Popup, Tooltip } from "react-leaflet";
import { useNavigate } from "react-router";
import { StationMapResponse } from "../../models/station-map";
import { ScContainer } from "../stations/grid/grid-styles";
import StationCard from "../stations/grid/StationCardSignalR";

interface Props {
  station: StationMapResponse;
}

const MapStation = ({ station }: Props) => {
  const navigate = useNavigate();

  const eventHandlers = useMemo(
    () => ({
      dblclick() {
        navigate(`/stations/${station.StationId}`);
      },
    }),
    [navigate, station.StationId]
  );

  const circleStyle = {
    color: "#212121",
    fillColor: station.AlarmState ? "#FFEB3B" : "#8BC34A",
    fillOpacity: 0.9,
    weight: 2.5,
    opacity: 0.9,
  };

  return (
    <CircleMarker
      center={[station.Latitude, station.Longitude]}
      pathOptions={circleStyle}
      eventHandlers={eventHandlers}
    >
      <Popup>
        {/* <LxCard
          title={`Station ${station.StationId} - ${station.SiteName}`}
          footer={`Last Update ${station.LastUpdate}`}
        >
          <MapStatusTable id={station.StationId} />
        </LxCard> */}
        <ScContainer>
          <StationCard station={station} />
        </ScContainer>
      </Popup>
      <Tooltip>{`${station.StationId} - ${station.SiteName}`}</Tooltip>
    </CircleMarker>
  );
};

export default MapStation;
