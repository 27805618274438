import { Container, Table } from "react-bootstrap";
import { StationReportHourly } from "../../models/station-list";
import LxCard from "../layout/LxCard";
import ReportExport from "./ReportExport";

interface Props {
  type: "Count" | "OnTime" | "OffTime";
  data: StationReportHourly[];
  stationId: string;
}

const HourlyReport = ({ type, data, stationId }: Props) => {
  const disabled = data.length <= 0;

  return (
    <LxCard
      title={`Hourly ${type} Report`}
      footer={
        <ReportExport
          disabled={disabled}
          report={data}
          title={`Station ${stationId} Hourly ${data[0].Point} Report.xlsx`}
          type="hourly"
        />
      }
    >
      <Container>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">Point</th>
              <th scope="col">Date</th>
              <th scope="col">Hourly Total</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.ReportDate}>
                <td>{row.Point}</td>
                <td>{row.ReportDate}</td>
                <td>{row.HourlyTotal.toFixed(1)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </LxCard>
  );
};

export default HourlyReport;
