import L, { Layer, Map as LeafletMap } from "leaflet";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import LxCard from "../components/layout/LxCard";
import MapContent from "../components/map/MapContent";
import AppSettings from "../helpers/app-settings";
import { useMapData } from "../hooks/use-signalr";
import { BreadCrumbPath } from "../models/breadcrumb-path";

const { BaseLayer } = LayersControl;

// Set up tile layer urls
const streetsUrl = `${AppSettings.MapBaseUrl}/${AppSettings.MapStreetTilesId}/tiles/{z}/{x}/{y}?access_token=${AppSettings.MapApiKey}`;
const satelliteUrl = `${AppSettings.MapBaseUrl}/${AppSettings.MapSatelliteTilesId}/tiles/{z}/{x}/{y}?access_token=${AppSettings.MapApiKey}`;
// const offlineUrl = "http://10.10.3.33/hot/{z}/{x}/{y}.png";

const Map = () => {
  const paths: BreadCrumbPath[] = [
    {
      name: "Home",
      link: "/",
      active: false,
    },
    {
      name: "Map",
      link: "/map",
      active: true,
    },
  ];

  useEffect(() => {
    document.title = "LxPro - Map";
  }, []);

  // Holds the map to access it's properties to dynamically
  // update the view bounds
  const [map, setMap] = useState<LeafletMap | null>(null);

  // Holds the list of stations retrieved from the backend
  // these are passed down to the content and also checked
  // to update the map's view bounds if stations are added
  // or removed
  // const { isLoading, data } = useQuery("map", agent.Stations.map, {
  //   refetchInterval: 5000,
  // });

  const data = useMapData();

  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  // Updates the map's view bounds when the map or the
  // list of stations is changed
  useEffect(() => {
    if (!map) return;
    if (data && data.length > 0) {
      const visibleMarkers: Layer[] = [];
      map.eachLayer((layer) => {
        if (layer instanceof L.CircleMarker) {
          visibleMarkers.push(layer);
        }
      });

      if (visibleMarkers.length > 0 && firstLoad) {
        const featureGroup = L.featureGroup(visibleMarkers).getBounds();

        map.fitBounds(featureGroup, { maxZoom: 12 });

        setFirstLoad(false);
      }
    }
  }, [map, data, firstLoad]);

  return (
    <>
      <AutoBreadCrumb paths={paths} />
      {data.length === 0 && <Spinner animation="border" />}
      {data && data.length > 0 && (
        <LxCard title="Map">
          <MapContainer
            center={[0, 0]}
            zoom={12}
            scrollWheelZoom={true}
            id="mapid"
            attributionControl={false}
            ref={setMap}
          >
            <LayersControl position="bottomleft">
              {/* <BaseLayer checked name="Offline">
              <TileLayer url={offlineUrl} />
            </BaseLayer> */}
              <BaseLayer checked name="Streets">
                <TileLayer url={streetsUrl} />
              </BaseLayer>
              <BaseLayer name="Satellite">
                <TileLayer url={satelliteUrl} />
              </BaseLayer>
            </LayersControl>
            <MapContent stations={data} />
          </MapContainer>
        </LxCard>
      )}
    </>
  );
};
export default Map;
