import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  ColumnFiltersState,
  SortingState,
  useTableInstance,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { StationOverviewList } from "../../../models/station-overview";
import PollButton from "../PollButton";
import StationButton from "../StationButton";
import Filter from "./Filter";

class CastError extends Error {}

function toNumber(v: unknown): number {
  let ret: number | undefined;
  switch (typeof v) {
    case "string":
      ret = parseFloat(v);
      break;
    case "number":
      ret = v;
      break;
  }
  if (ret === undefined || isNaN(ret)) {
    throw new CastError(`Can not cast to number: ${v}`);
  }
  return ret;
}

interface Props {
  data: StationOverviewList[];
}

let table = createTable().setRowType<StationOverviewList>();

function StationOverviewTableList({ data }: Props) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo(
    () => [
      table.createDataColumn("StationId", {
        cell: (info) => (
          <StationButton stationId={info.getValue()} />
        ),
        header: () => <span>Station ID</span>,
      }),
      table.createDataColumn("SiteName", {
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
      }),
      table.createDataColumn("Region", {
        cell: (info) => info.getValue(),
        header: () => <span>Region</span>,
      }),
      table.createDataColumn("AlarmState", {
        cell: (info) => info.getValue(),
        header: () => <span>Alarms</span>,
      }),
      table.createDataColumn("SignalStrength", {
        cell: (info) => info.getValue(),
        header: () => <span>Signal Strength</span>,
      }),
      table.createDataColumn("LastUpdate", {
        cell: (info) => info.getValue(),
        header: () => <span>Last Update</span>,
        enableColumnFilter: false,
      }),
    ],
    []
  );

  // const rerender = useReducer(() => ({}), {})[1];

  const [sorting, setSorting] = useState<SortingState>([]);

  const instance = useTableInstance(table, {
    data,
    columns,
    state: {
      columnFilters,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Table responsive striped hover>
      <thead>
        {instance.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {header.renderHeader()}
                        {{
                          asc: (
                            <>
                              {" "}
                              <FontAwesomeIcon icon="angle-up" />
                            </>
                          ),
                          desc: (
                            <>
                              {" "}
                              <FontAwesomeIcon icon="angle-down" />
                            </>
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} instance={instance} />
                        </div>
                      ) : null}
                    </>
                  )}
                </th>
              );
            })}
            <th>Poll</th>
          </tr>
        ))}
      </thead>
      <tbody>
        {instance.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={
              row.getVisibleCells()[3].getValue() === "ALARM"
                ? "table-warning"
                : row.getVisibleCells()[4].getValue() === "COMM FAIL"
                ? "table-danger"
                : ""
            }
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>{cell.renderCell()}</td>
            ))}
            <td>
              <PollButton id={toNumber(row.getVisibleCells()[0].getValue())} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default StationOverviewTableList;
