import { useEffect } from "react";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import UserAlertSettings from "../components/users/UserAlertSettings";
import UserDetails from "../components/users/UserDetails";
import useAuth from "../contexts/useAuth";

const Account = () => {
  const { user } = useAuth();

  useEffect(() => {
    document.title = "LxPro - Account";
  }, []);

  return (
    <>
      <AutoBreadCrumb
        paths={[
          { name: "Home", link: "/", active: false },
          { name: "Account", link: "/account", active: true },
        ]}
      />
      <h1>Account Settings</h1>
      {user && <UserDetails id={user.Id.toString()} />}
      <UserAlertSettings />
    </>
  );
};

export default Account;
