import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import React from "react";
import { Button } from "react-bootstrap";
import {
  StationReportDaily,
  StationReportHourly,
} from "../../models/station-list";

interface Props {
  disabled: boolean;
  report: StationReportDaily[] | StationReportHourly[];
  title: string;
  type: "daily" | "hourly";
}

const ReportExport = ({ disabled, report, title, type }: Props) => {
  const exportRef = React.createRef<ExcelExport>();

  const exportExcel = () => {
    if (exportRef.current) {
      exportRef.current.save();
    }
  };

  return (
    <>
      <Button onClick={exportExcel} disabled={disabled}>
        Save
      </Button>
      <ExcelExport data={report} fileName={title} ref={exportRef}>
        <ExcelExportColumn field="Point" title="Point" />
        <ExcelExportColumn
          field={type === "daily" ? "ReportDay" : "ReportDate"}
          title="Date"
        />
        <ExcelExportColumn
          field={type === "daily" ? "DailyTotal" : "HourlyTotal"}
          title={type === "daily" ? "Daily Total" : "Hourly Total"}
        />
      </ExcelExport>
    </>
  );
};

export default ReportExport;
