import { StationOverview } from "../../../models/station-overview";
import { ScContainer } from "./grid-styles";
import StationCard from "./StationCardSignalR";

interface Props {
  stations: StationOverview[];
}

const StationGrid = ({ stations }: Props) => {
  
  return (
    <ScContainer>
      {stations.map((station) => (
        <StationCard key={station.StationId} station={station} />
      ))}
    </ScContainer>
  );
};

export default StationGrid;
