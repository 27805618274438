import { Container, Table } from "react-bootstrap";
import { AlarmLogResponse } from "../../models/alarm";
import LxCard from "../layout/LxCard";
import AlarmExport from "./AlarmExport";
import AlarmLogRow from "./AlarmLogRow";

interface Props {
  alarms: AlarmLogResponse[];
}

const AlarmLogTable = ({ alarms }: Props) => {
  const disabled = alarms.length <= 0;

  return (
    <LxCard
      title="Alarm History"
      footer={
        <AlarmExport
          disabled={disabled}
          report={alarms}
          title="Alarm History Report.xlsx"
        />
      }
    >
      <Container>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">On Time</th>
              <th scope="col">Off Time</th>
              <th scope="col">Duration</th>
              <th scope="col">Station Id</th>
              <th scope="col">Site Name</th>
              <th scope="col">Point</th>
              <th scope="col">Name</th>
              <th scope="col">State</th>
            </tr>
          </thead>
          <tbody>
            {alarms.map((row) => (
              <AlarmLogRow key={row.Id} alarm={row} />
            ))}
          </tbody>
        </Table>
      </Container>
    </LxCard>
  );
};

export default AlarmLogTable;
