import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import agent from "../../api/agent";
import { UserResponse, UserUpdateRequest } from "../../models/user";
import LxModal from "../layout/LxModal";
import UsersRow from "./UsersRow";
import UserUpdateForm from "./UserUpdateForm";

interface Props {
  users: UserResponse[];
  onUpdated: () => void;
}

NiceModal.register("edit-user", LxModal);

const UsersTable = ({ users, onUpdated }: Props) => {
  const editUserHandler = (user: UserResponse) => {
    showForm(user);
  };

  const handleUpdateUser = async (id: string, user: UserUpdateRequest) => {
    hideForm();
    try {
      const updatedUser = await agent.Users.update(id, user);
      toast.success(`Successfully updated user ${updatedUser.Id}`);
      onUpdated();
    } catch (error) {
      toast.error(`Error updating user ${id}`);
    }
  };

  const handleDeleteUser = async (id: string) => {
    hideForm();
    try {
      await agent.Users.delete(id);
      toast.success(`Successfully deleted user ${id}`);
      onUpdated();
    } catch (error) {
      toast.error(`Error deleting user ${id}`);
    }
  };

  const showForm = (user: UserResponse) => {
    if (user) {
      NiceModal.show("edit-user", {
        title: `Edit User ${user.Id}`,
        children: <UserUpdateForm user={user} onUpdate={handleUpdateUser} />,
      });
    }
  };

  const hideForm = () => {
    NiceModal.hide("edit-user");
  };

  return (
    <>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Role</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <UsersRow
              key={user.Id}
              user={user}
              onEdit={editUserHandler}
              onDelete={handleDeleteUser}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default UsersTable;
