import { useEffect } from "react";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import ManageStations from "../components/manage/ManageStations";

const Manage = () => {
  useEffect(() => {
    document.title = "LxPro - Manage";
  }, []);
  return (
    <>
      <AutoBreadCrumb
        paths={[
          { name: "Home", link: "/", active: false },
          { name: "Config", link: "/", active: true },
          { name: "Stations", link: "/config/stations", active: true },
        ]}
      />
      <h1>Stations</h1>
      <ManageStations />
    </>
  );
};

export default Manage;
