const AppSettings = {
  ApiBaseUrl: window._env_.API_URL,
  CompanyName: window._env_.COMPANY_NAME,
  MapApiKey:
    "pk.eyJ1IjoiYmNpdGVjaCIsImEiOiJTVWdMaTZBIn0.9M9Lpj40mBDBC0R8akcf7g",
  MapStreetTilesId: "streets-v9",
  MapSatelliteTilesId: "satellite-streets-v9",
  MapBaseUrl: "https://api.mapbox.com/styles/v1/mapbox",
};

export default AppSettings;
