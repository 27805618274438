import { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import agent from "../api/agent";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import LxCard from "../components/layout/LxCard";
import LxModal from "../components/layout/LxModal";
import UserCreateForm from "../components/users/UserCreateForm";
import UsersTable from "../components/users/UsersTable";
import { UserCreateRequest } from "../models/user";

NiceModal.register("create-user", LxModal);

const Users = () => {
  const { isLoading, data } = useQuery("users", agent.Users.list);
  const queryClient = useQueryClient();

  useEffect(() => {
    document.title = "LxPro - Users";
  }, []);

  const updateUserList = () => {
    queryClient.invalidateQueries("users");
  };

  const createUserHandler = async (user: UserCreateRequest) => {
    hideForm();

    try {
      const createdUser = await agent.Users.create(user);
      toast.success(`User ${createdUser.Id} created!`);
      queryClient.invalidateQueries("users");
    } catch (error) {
      toast.error("Error creating user");
    }
  };

  const showForm = () => {
    NiceModal.show("create-user", {
      title: "Create New User",
      children: <UserCreateForm onCreate={createUserHandler} />,
    });
  };

  const hideForm = () => {
    NiceModal.hide("create-user");
  };

  return (
    <>
      <AutoBreadCrumb
        paths={[
          { name: "Home", link: "/", active: false },
          { name: "Config", link: "/", active: true },
          { name: "Users", link: "/config/users", active: true },
        ]}
      />
      <h1>Users</h1>
      <LxCard title="Users">
        {!isLoading && data && (
          <>
            <UsersTable users={data} onUpdated={updateUserList} />
            <Button onClick={() => showForm()}>Add</Button>
          </>
        )}
        {isLoading && <Spinner animation="border" />}
      </LxCard>
    </>
  );
};

export default Users;
