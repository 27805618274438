import { Duration } from "luxon";
import { AlarmLogResponse } from "../../models/alarm";

interface Props {
  alarm: AlarmLogResponse;
}

const AlarmLogRow = ({ alarm }: Props) => {
  const duration = Duration.fromObject({ seconds: alarm.Duration });

  return (
    <tr>
      <td>{alarm.OnTime}</td>
      <td>{alarm.OffTime}</td>
      <td>{duration.toFormat("hh:mm:ss")}</td>
      <td>{alarm.StationId}</td>
      <td>{alarm.SiteName}</td>
      <td>{alarm.Point}</td>
      <td>{alarm.Name}</td>
      <td>{alarm.State}</td>
    </tr>
  );
};

export default AlarmLogRow;
