import { StationMapResponse } from "../../models/station-map";
import MapStation from "./MapStation";

interface Props {
  stations: StationMapResponse[];
}

const MapContent = ({ stations }: Props) => {
  return (
    <>
      {stations.map((station) => {
        return <MapStation key={station.StationId} station={station} />;
      })}
    </>
  );
};

export default MapContent;
